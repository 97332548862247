import { useState, useEffect } from "react";
import axiosInstance from '../actions/index';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import 'tw-elements';
import Header from "../components/header";
import Username from "../components/username";
import EventType from "../components/eventType";
import ConnectGoogleCalendar from "../components/connectGoogleCalendar";
import Stepper from "../components/stepper";
import { trackEvent } from "../utils/tracking";

let Home = ({ styles }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userHasAccessQueryParam = params.get('userHasAccess');

  const [userdata, setUserData] = useState(null);
  const [userHasAccess, setUserHasAccess] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [eventType, setEventType] = useState([]);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  // redirect from backend might send a userHasAccess=false whenever the user has not granted all the scopes
  useEffect(() => {
    if (userHasAccessQueryParam == 'false') {
      setUserHasAccess(false)
    } else {
      setUserHasAccess(true)
    }
  }, [])

  useEffect(() => {
    axiosInstance.get('/event-type', { withCredentials: true })
      .then(response => {
        setEventType(response.data);
      })
      .catch(error => console.log(error.message))
  }, [userdata])

  useEffect(() => {
    axiosInstance.get('/home', { withCredentials: true })
      .then(response => {
        let { data } = response
        setUserData(data);

        if (data.language && data.language != i18n.language) {
          i18n.changeLanguage(data.language);
        }

        if (data.is_new_account) {
          axiosInstance.post('/account/edit', { "timezone": timezone }, { withCredentials: true })
            .then(() => {
              setUserData({
                ...userdata,
                timezone: timezone,
              });
            })
            .catch(error => console.log(error.response.data))

          // Create the first demo calendar here given its the last onboarding step
          let values = {
            name: '30min',
            description: '',
            duration: '30m',
            "daily_availability":
            {
              "1": [{ "start": "09:00", "end": "17:00" }],
              "2": [{ "start": "09:00", "end": "17:00" }],
              "3": [{ "start": "09:00", "end": "17:00" }],
              "4": [{ "start": "09:00", "end": "17:00" }],
              "5": [{ "start": "09:00", "end": "17:00" }]
            },
            timezone: timezone,
          }

          axiosInstance.post('/event-type', values, { withCredentials: true })
            .then(() => {
              axiosInstance.get('/event-type', { withCredentials: true })
                .then(response => {
                  setEventType(response.data);
                })
                .catch(error => console.log(error.message))
            })
            .catch(error => {
              console.log(error.message)
            })
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          setUserHasAccess(false);
          setUserData({});
        } else {
          navigate('/');
        }
      })
  }, [userHasAccess])

  if (userdata == null || userHasAccess == null) {
    return <div>{t('common.loading')}</div>;
  }

  const handleUpdateUsername = (newUsername) => {
    const payload = {
      username: newUsername
    }

    axiosInstance.post('/account/edit', payload, { withCredentials: true })
      .then(() => {
        setUserData({
          ...userdata,
          username: newUsername,
        });


      })
      .catch(error => console.log(error.message))
    trackEvent("signup", { username: newUsername });
  }

  const handleEditEventTypeOpen = (eventTypeID) => {
    navigate('/event-type/' + eventTypeID + '/edit')
  };

  const handleDeleteEventType = (eventTypeID) => {
    axiosInstance.delete('/event-type/' + eventTypeID, { withCredentials: true })
      .then(() => {
        axiosInstance.get('/event-type', { withCredentials: true })
          .then(response => {
            setEventType(response.data);
          })
          .catch(error => console.log(error.message))
      })
      .catch(error => {
        console.log(error.message)
      })

  }

  const steps = [
    {
      label: t('home.step.custom_url'),
      content: <Username styles={styles} handleUpdateUsername={handleUpdateUsername} action={"create"} />,
      completed: userdata.username
    },
    {
      label: t('home.step.connect_google'),
      content: <ConnectGoogleCalendar>
        <div className="flex items-center px-6 py-2 border-2 bg-white border-black text-black  font-medium text-xs leading-tight rounded-full hover:bg-gray-200 hover:bg-opacity-3 focus:outline-none focus:ring-0 transition duration-150 ease-in-out m-2">
          <div className="w-4 h-4 mr-2">
            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <path fill="#4285F4" d="M17.64 9.204c0-.63-.056-1.242-.16-1.836H9v3.47h4.812c-.204 1.132-.796 2.084-1.668 2.73v2.268h2.704c1.58-1.452 2.484-3.584 2.484-6.632z" />
              <path fill="#34A853" d="M9 18c2.28 0 4.18-.756 5.592-2.048l-2.704-2.268c-.752.508-1.716.812-2.888.812-2.22 0-4.096-1.494-4.756-3.5H1.776v2.204C3.168 15.204 5.58 18 9 18z" />
              <path fill="#FBBC05" d="M4.244 10.756c-.18-.54-.284-1.116-.284-1.756s.104-1.216.284-1.756V4.796H1.776C.644 6.232 0 8.044 0 10s.644 3.768 1.776 5.204l2.468-2.448z" />
              <path fill="#EA4335" d="M9 3.6c1.252 0 2.38.432 3.268 1.288l2.448-2.468C13.18.888 11.28 0 9 0 5.58 0 3.168 2.796 1.776 4.796l2.468 2.448C4.904 4.494 6.78 3.6 9 3.6z" />
              <path fill="none" d="M0 0h18v18H0z" />
            </svg>
          </div>
          <span className="text-sm">{t('component.connectGoogleCalendar.title')}</span>
        </div>
      </ConnectGoogleCalendar>,
      completed: userHasAccess,
    },
  ];

  if (!onboardingCompleted && steps.every(step => step.completed)) {
    setOnboardingCompleted(true)
  }

  return (
    <div className="flex h-screen overflow-hidden  bg-gray-100">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          fullname={userdata.fullname}
          avatar={userdata.avatar} />

        <main>
          {/* <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto"> */}
          <div className="grid grid-cols-3 gap-4 mt-8">

            <div className="col-span-3">
              {/* Cards */}
              <div className="flex w-full justify-center">
                {!onboardingCompleted && <Stepper steps={steps} />}
              </div>

              <div className="flex w-full justify-center">
                {onboardingCompleted &&
                  <button className={styles.buttonPrimary} onClick={() => navigate('/event-type')}>
                    {t('home.create_event_type_button')}
                  </button>}
              </div>

              <div className="flex w-full justify-center">
                {onboardingCompleted &&
                  <EventType
                    eventType={eventType}
                    username={userdata.username}
                    handleEditEventType={handleEditEventTypeOpen}
                    handleDeleteEventType={handleDeleteEventType} />}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Home;
