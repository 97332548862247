import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack';
import { Formik, Form } from "formik";

import axiosInstance from '../actions/index';
import { trackEvent } from "../utils/tracking";
import EventTypeForm from "../components/eventTypeForm";
import Booking from '../components/booking';
import BookingHeader from "../components/bookingHeader";
import EventTypeCreated from "../components/eventTypeCreated";
import SaveButton from "../components/saveButton";

const CreateEventTypeContainer = ({ userdata, styles }) => {
    const { t } = useTranslation();
    const [timezone, setTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    let navigate = useNavigate();
    const domain = window.location.origin;

    const [availableSlots, setAvailableSlots] = useState([])
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
    const [eventTypeCreatedModal, setEventTypeCreatedModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [eventTypeCreatedUrl, setEventTypeCreatedUrl] = useState("");
    const [eventTypeCreatedUUID, setEventTypeCreatedUUID] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        duration: '15m',
        daily_availability: {
            1: [{ start: '09:00', end: '18:00' }],
            2: [{ start: '09:00', end: '18:00' }],
            3: [{ start: '09:00', end: '18:00' }],
            4: [{ start: '09:00', end: '18:00' }],
            5: [{ start: '09:00', end: '18:00' }],
            6: [{ start: '', end: '' }],
            0: [{ start: '', end: '' }],
        },
        timezone: timezone,
        meeting_location: [],
        recurrence: {
            type: 'weekly'
        },
        custom_question: []
    });

    const handleInputChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const handleCreateEventType = (values) => {
        setIsSubmitting(true)
        axiosInstance.post('/event-type', values, { withCredentials: true })
            .then((resp) => {
                setIsSubmitting(false)
                const { uuid } = resp.data
                axiosInstance.get(`/event-type/${uuid}`, { withCredentials: true })
                    .then((resp) => {
                        const eventType = resp.data
                        trackEvent("create-booking-page", { booking_page_name: eventType.name })
                        setEventTypeCreatedUUID(uuid)
                        setEventTypeCreatedUrl(`${domain}/${userdata.username}/${eventType.name}`)
                        setEventTypeCreatedModal(true)
                    })
                    .catch(error => console.log(error.message))
            })
            .catch(error => {
                setIsSubmitting(false)
                if (error.response.status === 500) {
                    enqueueSnackbar('Internal server error', { variant: 'error' });
                }

                if (error.response.status === 400) {
                    if (error.response.data.message === "duplicated_event_name") {
                        enqueueSnackbar(t('component.new_event_type.error_duplicated_event_name'), { variant: 'warning' });
                    } else {
                        enqueueSnackbar('Bad request', { variant: 'warning' });
                    }
                }
            })
    }

    useEffect(() => {
        // Check if formData.duration is a string to avoid wrong calls to the endpoint
        if (typeof formData.duration === 'string') {
            axiosInstance.post('/event-type/simulate-available-slots', formData, { withCredentials: true })
                .then(response => {
                    setAvailableSlots(response.data);
                })
                .catch(error => console.log(error.message))
        }
    }, [formData.daily_availability, formData.duration, formData.recurrence])


    return (
        <>
            <EventTypeCreated
                open={eventTypeCreatedModal}
                setOpen={setEventTypeCreatedModal}
                url={eventTypeCreatedUrl}
                handleClose={() => navigate(`/event-type/${eventTypeCreatedUUID}/edit`)}
                navigateHome={() => navigate("/home")
                }
            />

            <Formik initialValues={formData} validateOnBlur={false} validateOnChange={true} onSubmit={(values) => { handleCreateEventType(values) }}>
                {({ setFieldValue, errors, touched }) => (
                    <Form>
                        <div className="flex flex-col md:grid grid-cols-2 md:mx-5 px-10">
                            <div className="md:flex flex-col col-span-1 w-full items-start">
                                <span className="uppercase tracking-wide text-gray-900 text-lg font-bold mb-2">{t('component.new_event_type.title')}</span>
                            </div>
                        </div>
                        <div className="flex flex-col md:grid grid-cols-5 gap-4 md:mx-5 p-10">
                            {/* <!--Left Col--> */}
                            <div className="md:flex flex-col col-span-2 w-full items-center">
                                <EventTypeForm
                                    isSubmitting={isSubmitting}
                                    styles={styles}
                                    formData={formData}
                                    onInputChange={handleInputChange}

                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                />
                            </div>

                            {/* <!--Right Col--> */}
                            <div className="flex flex-col col-span-3 h-fit w-full sticky top-10 space-y-2">
                                <div className="flex flex-row-reverse">
                                    <SaveButton isSaving={isSubmitting} />
                                </div>
                                <div className="hidden md:flex flex-col h-fit w-full shadow-lg rounded items-center pt-10 bg-white">
                                    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">
                                        <svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                        </svg>
                                        {t('component.new_event_type.preview_badge')}
                                    </span>
                                    <div className='md:grid md:grid-cols-7'>
                                        <div name="calendar_header" className="md:col-span-2">
                                            <BookingHeader eventTypeInfo={formData} />
                                        </div>
                                        <div className='md:col-span-5'>
                                            <div className="text-gray-900 py-5">
                                                <Booking
                                                    availableSlots={availableSlots}
                                                    handleSelectTimeSlot={setSelectedTimeSlot}
                                                    timezone={timezone}
                                                    setTimezone={setTimezone}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>

    )
}

export default CreateEventTypeContainer;
