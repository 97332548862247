import React, { useState } from "react";

const Alert = ({ color, message, type }) => {
    const [showAlert, setShowAlert] = useState(true);
    var bgcolor = `bg-${color}-500`
    return (
        <>
            {showAlert ? (
                <div className={"text-white px-6 py-4 border-0 rounded relative mb-4 " + bgcolor}>
                    <span className="text-xl inline-block mr-5 align-middle">
                        <i className="fas fa-bell" />
                    </span>
                    <span className="inline-block align-middle mr-8">
                        <b className="capitalize">{type}!</b> {message}
                    </span>
                    <button
                        className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
                        onClick={() => setShowAlert(false)}
                    >
                        <span>×</span>
                    </button>
                </div>
            ) : null}
        </>
    );
};

export default Alert;