import { useTranslation } from 'react-i18next'
import { Formik, Field, Form } from "formik";
import * as moment from 'moment-timezone'

import Spinner from './spinner';

const BookingConfirmationForm = ({ selectedTimeSlot, setSelectedTimeSlot, confirmAppointment, timezone, customQuestion }) => {
    const { t, i18n } = useTranslation();

    const formattedSelectedTimeSlot = moment(selectedTimeSlot).format('YYYY-MM-DDTHH:mm:ssZ')

    return (
        <div className="m-2 sm:m-24">
            <div className="mx-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t('component.booking.confirmAppointment')}</h2>
                    <p className="mt-1 text-sm leading-6 text-blue-800">
                        {moment(selectedTimeSlot).format('D MMMM YYYY HH:mm')}
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
                        <div className="sm:col-span-6">
                            <Formik
                                initialValues={{ guest_email: '', guest_name: '', custom_question: [] }}
                                validateOnBlur={false}
                                validateOnChange={true}
                                onSubmit={(values, { setSubmitting }) => {

                                    // Transform custom question responses into an array
                                    const customQuestionResponses = customQuestion.map(question => (
                                        {
                                            "id": question.id,
                                            "text": values[question.id],
                                            "type": question.type,
                                        }
                                    ));
                                    // Update the values object with the array of custom question responses
                                    const updatedValues = {
                                        ...values,
                                        custom_question: customQuestionResponses
                                    };

                                    // Delete the individual custom question responses from the values object
                                    customQuestion.forEach(question => delete updatedValues[question.id]);

                                    // Submit the updated values
                                    confirmAppointment(formattedSelectedTimeSlot, updatedValues);

                                    // confirmAppointment(selectedTimeSlot, values)
                                    setSubmitting(true);
                                }}>
                                {({ isSubmitting, errors, touched }) => (
                                    <div>
                                        {isSubmitting && <Spinner />}
                                        <Form>
                                            <label htmlFor="guest_name" className="block text-sm font-medium leading-6 text-gray-900">
                                                {t('component.booking.nameField')}
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    type="name"
                                                    name="guest_name"
                                                    id="guest_name"
                                                    validate={(value) => validateNotEmpty(value)}
                                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="you name"
                                                />
                                                {errors.guest_name && touched.guest_name && <div className="text-red-400 text-xs m-5">{t(errors.guest_name)}</div>}
                                            </div>
                                            <label htmlFor="guest_email" className="block text-sm font-medium leading-6 text-gray-900">
                                                {t('component.booking.emailField')}
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    type="email"
                                                    name="guest_email"
                                                    id="guest_email"
                                                    validate={(value) => validateNotEmpty(value)}
                                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="you@example.com"
                                                />
                                                {errors.guest_email && touched.guest_email && <div className="text-red-400 text-xs m-5">{t(errors.guest_email)}</div>}
                                            </div>

                                            {/* check customQuestion is not nil */}
                                            {
                                                customQuestion && customQuestion.map(question => (
                                                    <>
                                                        <label htmlFor={question.id} className="block text-sm font-medium leading-6 text-gray-900">
                                                            {question.text}
                                                        </label>
                                                        <div className="mt-2">
                                                            <Field
                                                                type="text"
                                                                name={question.id}
                                                                id={question.id}
                                                                className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </>
                                                ))
                                            }

                                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                                <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => setSelectedTimeSlot(null)}>
                                                    {t('common.cancel_button')}
                                                </button>
                                                <button
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    className="rounded-md bg-indigo-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    {t('component.booking.confirmButton')}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BookingConfirmationForm;

const validateNotEmpty = (value) => {
    if (value.length === 0) {
        return "component.booking.error_can_not_be_empty";
    }
};