import { Field } from "formik";
import { useTranslation } from 'react-i18next'

import * as moment from 'moment-timezone'

import Availability from "./availability";
import DurationSelect from "./durationSelect";
import MeetingLocation from "./meetingLocation";
import Recurrence from "./recurrence";
import Reminders from "./reminders";
import CustomQuestion from "./customQuestion";

let EventTypeForm = ({ styles, formData, onInputChange, setFieldValue, errors, touched }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-wrap -mx-3 p-8 shadow-lg rounded bg-white space-y-8">
            <div className="w-full">
                <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.new_event_type.name')}</label>
                <Field
                    type="text"
                    name="name"
                    onChange={(e) => {
                        setFieldValue('name', e.target.value);
                        onInputChange('name', e.target.value);
                    }}
                    validate={(value) => validateEventName(value)}
                    className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
                {errors.name && touched.name && <div className="text-red-400 text-xs m-5">{t(errors.name)}</div>}
            </div>
            <div className="w-full">
                <label htmlFor="description" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{t('component.new_event_type.description')}</label>
                <Field
                    as="textarea"
                    name="description"
                    onChange={(e) => {
                        setFieldValue('description', e.target.value);
                        onInputChange('description', e.target.value);
                    }}
                    validate={(value) => validateCharacterLimit(value, 300)}
                    className="appearance-none block w-full h-32 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                />
                {errors.description && touched.description && <div className="text-red-400 text-xs m-5">{t(errors.description)}</div>}
            </div>

            <div className="w-full">
                <Field
                    name="duration"
                    component={DurationSelect}
                    onInputChange={onInputChange}
                    className={styles.dropdown}
                    styles={styles}
                    defaultDuration={formData.duration}
                    validate={(value) => validateDuration(value)}
                />
                {errors.duration && touched.duration && <div className="text-red-400 text-xs m-5">{t(errors.duration)}</div>}
            </div>
            <div className="w-full">
                <Field
                    name="meeting_location"
                    component={MeetingLocation}
                    styles={styles}
                    defaultLocation={formData.meeting_location}
                />
            </div>
            <div className="w-full">
                <Field
                    name="daily_availability"
                    styles={styles}
                    component={Availability}
                    onInputChange={onInputChange}
                    defaultAvailability={formData.daily_availability}
                    validate={validateAvailability}
                />
                {errors.availability && touched.availability && <div className="text-red-400 text-xs m-5">{t(errors.availability)}</div>}
            </div>
            <div className="w-full">
                <Field
                    name="recurrence"
                    onInputChange={onInputChange}
                    defaultRecurrence={formData.recurrence}
                    component={Recurrence}
                />
            </div>
            <div className="w-full">
                <Field
                    name="reminders"
                    onInputChange={onInputChange}
                    defaultReminders={formData.reminders}
                    component={Reminders}
                />
            </div>
            <div className="w-full">
                <Field
                    name="custom_question"
                    onInputChange={onInputChange}
                    defaultCustomQuestion={formData.custom_question}
                    component={CustomQuestion}
                />
            </div>
        </div>
    )
}

export default EventTypeForm;

const validateEventName = (value) => {
    let err = validateNotEmpty(value)
    if (err) {
        return err
    }

    return validateCharacterLimit(value, 200)
}

const validateDuration = (value) => {
    if (value == "" || value == "m") {
        return "component.new_event_type.error_can_not_be_empty";
    }
}

const validateAvailability = (availability) => {
    const weekdays = [1, 2, 3, 4, 5, 6, 0]

    let error = "";
    weekdays.map(day => {
        if (availability[day] && availability[day][0] && moment(availability[day][0].start, 'HH:mm').isAfter(moment(availability[day][0].end, 'HH:mm'))) {
            error = "component.new_event_type.error_end_before_start"
        }
    })

    return error
}

const validateCharacterLimit = (value, maxLimit) => {
    if (value.length > maxLimit) {
        return "component.new_event_type.error_character_limit_exceeded";
    }
};

const validateNotEmpty = (value) => {
    if (value.length === 0) {
        return "component.new_event_type.error_can_not_be_empty";
    }
};