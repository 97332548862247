import React, { useEffect, useState } from 'react';
import './App.css';
import BookingPage from './pages/booking';
import AccountPage from './pages/account';
import ContactPage from './pages/contact';
import Landing from './pages/landing';
import CreateEventType from './pages/createEventType';
import EditEventType from './pages/editEventType';
import Home from './pages/home';
import Login from './pages/login';
import Redirect401 from './actions/redirect401';
import Privacy from './pages/privacy';
import { Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import ConfirmedAppointmentPage from './pages/confirmedAppointment';
import { usePageTracking } from './utils/tracking';
import HelpScoutTagWrapper from './utils/HelpScoutTagWrapper';
import { Helmet } from 'react-helmet';
import { SnackbarProvider } from 'notistack';
import ProPlan from './pages/proPlan';
import ZoomCallback from './components/zoomCallback';

const styles = {
  label: 'form-label inline-block mb-2 text-gray-700 m-1',
  field:
    'mx-auto bg-gray-50 text-gray-700 border border-gray-300 rounded py-1 px-4 m-1',
  buttonSecondary:
    'inline-block px-6 py-2 border-2 border-red-600 text-red-600 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out m-2',
  buttonPrimary:
    'inline-block px-6 py-2 border-2 border-blue-600 text-blue-600 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out m-2',
  errorMsg: 'text-red-500 text-sm',
  dropdown:
    'dropdown-toggle bg-gray-50 text-gray-700 border border-gray-300 rounded py-1 px-4 m-1',
  customSelectStyles: {
    control: (provided, state) => ({
      ...provided,
      borderColor: 'gray-200', // change border color here
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'black' : 'gray', // change text color here
    }),
  }
}

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [language, setLanguage] = useState('en');

  usePageTracking();

  useEffect(() => {
    const l = detectLanguage(location.pathname)
    setLanguage(l);
    i18n.changeLanguage(l);

    // change the <html lang=""> tag 
    document.documentElement.lang = language;

    // add canonical links
    if (language === 'en') {
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (canonicalLink) {
        canonicalLink.href = "https://www.cronoscal.com/";
      }
    } else if (language === 'es') {
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (canonicalLink) {
        canonicalLink.href = "https://www.cronoscal.com/es/";
      }
    }
  }, [language]); // Only call once, after component has mounted

  useEffect(() => {
    // The Google Analytics script has been loaded, you can now use gtag function
    window.onload = () => {
      window.gtag('js', new Date());
      window.gtag('config', 'G-XSJLPB0WLL', { send_page_view: false });
    }
  }, []);

  return (
    <div className="App">
      <Helmet>
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-XSJLPB0WLL"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }`}
        </script>

        {/* Clarity */}
        <script type="text/javascript">
          {`(function (c, l, a, r, i, t, y) {
              c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
              t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
              y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
            })(window, document, "clarity", "script", "hxspifmmz0");`}
        </script>

      </Helmet>

      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <SnackbarProvider maxSnack={3}>
          <Redirect401 />
          <Routes>
            <Route path='/' element={
              <HelpScoutTagWrapper includeHelpScoutTag={true}>
                <Landing styles={styles} />
              </HelpScoutTagWrapper>}
            />
            <Route path='/es' element={
              <HelpScoutTagWrapper includeHelpScoutTag={true}>
                <Landing styles={styles} />
              </HelpScoutTagWrapper>}
            />
            <Route path='/en' element={
              <HelpScoutTagWrapper includeHelpScoutTag={true}>
                <Landing styles={styles} />
              </HelpScoutTagWrapper>}
            />
            <Route path='/login' element={<Login styles={styles} />} />
            <Route path='/home' element={
              <HelpScoutTagWrapper includeHelpScoutTag={true}>
                <Home styles={styles} />
              </HelpScoutTagWrapper>}
            />
            <Route path="/pro-plan" element={<ProPlan />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/api/v1/zoom/callback" element={<ZoomCallback />} />

            <Route path='/event-type' element={<CreateEventType styles={styles} />} />
            <Route path='/event-type/:event_type_id/edit' element={<EditEventType styles={styles} />} />
            <Route path='/privacy' element={<Privacy />} />

            <Route path='/event-type/:event_type_id/event/:event_id' element={<ConfirmedAppointmentPage styles={styles} />} />
            <Route path='/:username/:calendar_id' element={<BookingPage />} />
          </Routes>
        </SnackbarProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

function detectLanguage(path) {
  const languageCodes = ['es', 'en'];

  const languageDetector = new LanguageDetector(null, detectionOptions);
  const detectedLang = languageDetector.detect().split('-')[0];

  for (let code of languageCodes) {
    if (path.startsWith(`/${code}/`)) {
      return code;
    }

    if (detectedLang == code) {
      return code
    }
  }

  return 'en'; // Return 'en' if no language code is found
}

export default App;

let detectionOptions = {
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',

  // cache user language
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
  //cookieMinutes: 10,
  //cookieDomain: 'myDomain'
};