import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { startOfToday, format } from 'date-fns';

import TimezoneSelect from 'react-timezone-select';

import Spinner from './spinner';
import BookingCalendar from './bookingCalendar';
import * as moment from 'moment-timezone'

const Booking = ({ availableSlots, isLoadingAvailableSlots, handleSelectTimeSlot, setTimezone, timezone }) => {
    const today = startOfToday()
    const slotRef = useRef();
    const [selectedDay, setSelectedDay] = useState(today)

    useEffect(() => {
        if (Object.keys(availableSlots).length > 0) {
            setSelectedDay(getFirstDayWithAvailability(availableSlots));
        }
    }, [availableSlots]);


    return (
        <div className="md:grid md:grid-cols-3  m-12">
            <div className='md:col-span-2'>
                <BookingCalendar selectedDay={selectedDay} availableSlot={availableSlots} setSelectedDay={setSelectedDay} slotRef={slotRef} />
                <div className="flex flex-end my-2">
                    <TimezoneSelect
                        id="timezone"
                        value={timezone}
                        onChange={(selectedTimezone) => setTimezone(selectedTimezone.value)}
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                border: 'none',
                            }),
                        }}
                    />
                </div>
            </div>
            <section ref={slotRef} className="md:col-span-1 mt-12 md:mt-0 md:pl-14">
                <div className="flex flex-col h-full">
                    <div className="flex-grow">
                        <span className="text-xs font-semibold leading-6 text-gray-900">
                            <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>{format(selectedDay, 'd MMM yyyy')}</time>
                        </span>
                        {isLoadingAvailableSlots && <Spinner />}
                        {!isLoadingAvailableSlots && <Slots selectedDay={selectedDay} availableSlots={availableSlots} handleSelectTimeSlot={handleSelectTimeSlot} timezone={timezone} />}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Booking

const Slots = ({ selectedDay, availableSlots, handleSelectTimeSlot, timezone }) => {
    const { t } = useTranslation();

    if (!availableSlots[format(selectedDay, 'yyyy-MM-dd')]) {
        return <p>{t('component.booking.no_available_slots')} </p>
    }

    const selectTimeSlot = (date, time, timezone) => {
        const dateTimeString = date + 'T' + time;
        const eventDate = moment.tz(dateTimeString, timezone);
        return handleSelectTimeSlot(eventDate);
    }

    return (
        <ol id="slots" className="mt-4 space-y-1 text-sm leading-6 text-gray-500 overflow-y-auto h-96">
            {availableSlots[format(selectedDay, 'yyyy-MM-dd')].map((availableSlot) => (
                <li
                    key={availableSlot}
                    onClick={() => selectTimeSlot(format(selectedDay, 'yyyy-MM-dd'), availableSlot, timezone)}
                    className="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100"
                >
                    <div className="flex-auto">
                        <p className="text-gray-900">{availableSlot}</p>
                    </div>
                </li>
            ))}
        </ol>
    )
}

const getFirstDayWithAvailability = (availability) => {
    // Get an array of the keys (dates) sorted in ascending order
    const dates = Object.keys(availability).sort((a, b) => new Date(a) - new Date(b));

    // Iterate over each date
    for (let date of dates) {
        // Check if there are any available slots on that date
        if (Array.isArray(availability[date]) && availability[date].length > 0) {
            return new Date(date).setHours(0);
        }
    }

    // If no available date is found, return null
    return null;
};
